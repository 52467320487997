import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './lib/router.js'
import vuetify from './plugins/vuetify'
import api from './lib/api'
import { init } from '@amplitude/analytics-browser'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueApexCharts from "vue-apexcharts"

Vue.use(VueApexCharts)
Vue.component("apexchart", VueApexCharts)

Apex.chart = {
	locales: [{
		"name": "ru",
		"options": {
			"months": ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
			"shortMonths": ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
			"days": ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
			"shortDays": ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
			"toolbar": {
				"exportToSVG": "Скачать SVG",
				"exportToPNG": "Скачать PNG",
				"menu": "Меню",
				"selection": "Выбор",
				"selectionZoom": "Увеличить выбранное",
				"zoomIn": "Увеличить",
				"zoomOut": "Уменьшить",
				"pan": "Panning",
				"reset": "Reset Zoom"
			}
		}
	}],
	defaultLocale: "ru"
}

// console.clear()

// Amplitude
init('f62fa84d6158eba06d3193f3c9481f21', null, {
	// optional configuration options
	saveEvents: true,
	includeUtm: true,
	includeReferrer: true
})

// Google Recaptcha
Vue.use(VueReCaptcha, {
	siteKey: '6Lf5UPMjAAAAAIHeZWHekvIiqDt-eeZuprIYxhEZ',
	loaderOptions: {
		autoHideBadge: true
	}
})

// Turn off production tip
Vue.config.productionTip = false

// TODO: Refactor to remove code duplication

// Read auth token from local storage
if (localStorage.getItem('token')) {
	api.post('auth/authorize').then(response => {

		// Initialize Vue
		var vm = new Vue({
			router,
			vuetify,
			store,
			render: h => h(App),
		}).$mount('#app')

		window.vm = vm

		store.dispatch("login", response.data)
	})
}
else {
	// Initialize Vue
	var vm = new Vue({
		router,
		vuetify,
		store,
		render: h => h(App),
	}).$mount('#app')

	window.vm = vm
}